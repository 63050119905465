<script setup lang="ts">
import { tokenLayers } from '#core/utils/const'

const props = defineProps<{
  layers: Array<string>
}>()
const tokenBaseLayers = computed(() => {
  return (props.layers || []).map((layer) => {
    if (!(layer in tokenLayers)) {
      return false
    }
    return {
      title: layer,
      alt: layer,
      ...tokenLayers[layer as keyof typeof tokenLayers],
    }
  }).filter(Boolean)
})
</script>

<template>
  <div data-ui="UiBaseLayer" class="rounded-full h-10 px-3 inline-flex gap-2 items-center text-forgd-gray-600 border border-forgd-gray-600/30 bg-forgd-gray-600/10">
    <div class="font-mono text-xs font-medium truncate">
      Token Base layer
    </div>
    <UAvatarGroup size="xs" :max="5" :ui="{ ring: 'ring-forgd-neutral-600 ring-1' }">
      <UAvatar
        v-for="(token, key) in tokenBaseLayers"
        :key="key"
        v-bind="token"
        class="bg-black"
      />
    </UAvatarGroup>
  </div>
</template>
